import React, { useEffect, useState } from "react";
import "./MainPage.scss";
import CounterBlock from "../CounterBlock/CounterBlock";
import MyVotesBlock from "../MyVotesBlock/MyVotesBlock";
import ActualBlock from "../ActualBlock/ActualBlock";
import ScanQRMobile from "../ScanQRMobile/ScanQRMobile";
import AmountVotesBlock from "../AmountVotesBlock/AmountVotesBlock";
// import ObserverCryptoBlock from "../ObserverCryptoBlock/ObserverCryptoBlock";
import CalendarVotes from "../CalendarVotes/CalendarVotes";
import * as Stats from "../../Api/Stats";
import EmptyStatesComponent from "../EmptyStatesComponent/EmptyStatesComponent";
import PreLoaderCallVotingPage from "../PreLoaderCallVotingPage/PreLoaderCallVotingPage";
import Loader from "../Loader/Loader";
import { useEvents } from "../../fetchers/useEvents";

const MainPage = (props) => {
  const {
    requestHelper,
    handleCurrentEvents,
    toggleEventRegistration,
    showEventResult,
    formatDate,
    formatTime,
    utcOffset,
    isLoggedIn,
    statsReload,
    setStatsReload,
    firstSubscribe,
    isJoinEventLoading = false,
  } = props;

  const {
    data: allEvents,
    isLoading,
    isError,
    isSuccess,
    isRefetching,
  } = useEvents();

  const [statsData, setStatsData] = useState(
    JSON.parse(localStorage.getItem("statsData")) || {}
  );
  const [isEmpty, setIsEmpty] = useState(false);

  const sortEvents = (events) => {
    if (events && Array.isArray(events) && events.length !== 0) {
      const sortedActualEvents = events
        ?.filter((el) => el.status !== "ended")
        ?.filter((el) => el.status !== "quorum_unpresant")
        ?.sort((a, b) =>
          a.registration_end_time > b.registration_end_time ? 1 : -1
        );
      return sortedActualEvents;
    }
    return events;
  };

  const [sortActualEvents, setSortActualEvents] = useState(
    sortEvents(allEvents)
  );

  useEffect(() => {
    setSortActualEvents(sortEvents(allEvents));
  }, [allEvents]);

  useEffect(() => {
    if (isLoggedIn && statsReload) {
      requestHelper(Stats.getStats).then((data) => {
        setStatsData(data);
        localStorage.setItem("statsData", JSON.stringify(data));
        setStatsReload(false);
      });
    }
    // eslint-disable-next-line
  }, [isLoggedIn, allEvents, statsReload]);

  useEffect(() => {
    setIsEmpty(sortActualEvents && sortActualEvents.length === 0);
  }, [sortActualEvents]);

  return (
    <div>
      <div className={"main-content__title"}>
        Добро пожаловать в КриптоВече!
      </div>
      {/* <CounterBlock stats={statsData} /> */}

      {isLoading || firstSubscribe || isJoinEventLoading ? (
        <Loader isLarge />
      ) : (
        <>
          {isEmpty ? (
            <div className={"main-content__empty-states-wrapper"}>
              <div className={"main-content__empty-states-myvotes-block"}>
                <MyVotesBlock
                  myVotes={sortActualEvents}
                  handleCurrentEvents={handleCurrentEvents}
                  toggleEventRegistration={toggleEventRegistration}
                  showEventResult={showEventResult}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                />
              </div>
              <div className={"main-content__empty-states-observer-amount"}>
                {/* <ObserverCryptoBlock /> */}
                <AmountVotesBlock
                  statsData={statsData}
                  formatDate={formatDate}
                />
              </div>
              <div className={"main-content__empty-states-component"}>
                <EmptyStatesComponent />
              </div>
              <div className={"main-content__empty-states-qr-code"}>
                <ScanQRMobile />
              </div>
            </div>
          ) : (
            <>
              <div className={"main-content__my-votes-actual"}>
                <MyVotesBlock
                  myVotes={sortActualEvents}
                  handleCurrentEvents={handleCurrentEvents}
                  toggleEventRegistration={toggleEventRegistration}
                  showEventResult={showEventResult}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                />
                {sortActualEvents && sortActualEvents.length !== 0 && (
                  <ActualBlock
                    sortActualEvents={sortActualEvents}
                    handleCurrentEvents={handleCurrentEvents}
                    toggleEventRegistration={toggleEventRegistration}
                    formatDate={formatDate}
                    formatTime={formatTime}
                  />
                )}
                {/* <ScanQRMobile /> */}
              </div>
              <div className={"main-content__amount-votes-and-calendar-votes"}>
                <div className={"gistogramma-and-observer-cryptoveche"}>
                  <AmountVotesBlock
                    statsData={statsData}
                    formatDate={formatDate}
                  />
                  {/* <ObserverCryptoBlock /> */}
                </div>
                <CalendarVotes
                  allEvents={allEvents}
                  formatTime={formatTime}
                  formatDate={formatDate}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MainPage;
