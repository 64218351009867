import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AuthorizationForgetPassword.css";
import logo from "../../img/Auth_logo_crypto_veche.svg";
import row_link from "../../img/Registration_row_icon.svg";
import AuthorizationForgetPasswordModal from "../AuthorizationForgetPasswordModal/AuthorizationForgetPasswordModal";
import * as Auth from "../../Api/Auth";
import { Validation } from "../../utils/Validation";

const AuthorizationForgetPassword = () => {
  const email = Validation();

  const [modalActive, setModalActive] = useState(false);
  const [hideBlock, setHideBlock] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [inputTypeIsEmail, setInputTypeIsEmail] = useState(true);

  const [disableButton, setDisableButton] = useState(false);

  function onSendEmailClick() {
    setDisableButton(true);
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email.value) === false) {
      setEmailErrorMessage("Введите корректный e-mail");
    } else {
      const requestType = inputTypeIsEmail ? "EMAIL" : "PHONE";

      Auth.getForgotPasswordRequest(requestType, email.value)
        .then((res) => {
          if (res.status === "ok") {
            setModalActive(true);
            setEmailErrorMessage("");
          }

          setDisableButton(false);
        })
        .catch((err) => {
          if (err.message) {
            setEmailErrorMessage(err.message);
            setDisableButton(false);
            return;
          }
          setEmailErrorMessage("Пользователь не найден");
          setDisableButton(false);
        });
    }
  }

  function onSendPhoneClick() {
    setDisableButton(true);
    const phoneNumberRegExp = /^(7)[0-9]{10}/;
    if (phoneNumberRegExp.test(email.value) === false) {
      setEmailErrorMessage("Введите корректный номер телефона");
    } else {
      Auth.sendEmailForgetPassword(email.value + "@sms.ru")
        .then(() => {
          setModalActive(true);
          setEmailErrorMessage("");
          setDisableButton(false);
        })
        .catch((err) => {
          if (err.message) {
            setEmailErrorMessage(err.message);
            return;
          }
          setEmailErrorMessage("Пользователь не найден");
          setDisableButton(false);
        });
    }
  }

  return (
    <div className="wrapper-auth-forget">
      <div className="container-auth-forget">
        <div className="main-bloсk-forget">
          <div className={hideBlock ? "main-block__auth" : "main-block__auth"}>
            <div className={"auth-forget__title"}>
              <Link to={"/auth"}>
                <img
                  alt={"стрелочка ссылка"}
                  className={"auth__link-row-icon"}
                  src={row_link}
                />
              </Link>

              <h3 className="auth-forget__title-name">Забыли пароль?</h3>
              {/*<div><span>РУС</span><span>ENG</span></div>*/}
            </div>
            <span className={"auth__description"}>
              Пожалуйста, укажите{" "}
              <span
                onClick={() => setInputTypeIsEmail(true)}
                className={
                  inputTypeIsEmail
                    ? "auth-forget__email-toggle active"
                    : "auth-forget__email-toggle"
                }
              >
                e-mail
              </span>{" "}
              или{" "}
              <span
                onClick={() => setInputTypeIsEmail(false)}
                className={
                  !inputTypeIsEmail
                    ? "auth-forget__phone-toggle active"
                    : "auth-forget__phone-toggle"
                }
              >
                номер телефона
              </span>
              , который Вы использовали для входа в личный кабинет, и мы пришлем
              Вам ссылку для смены пароля.
            </span>
            <div className={"auth__form"}>
              <div className={"form__e-mail"}>
                <input
                  type={inputTypeIsEmail ? "email" : "phone"}
                  id="register-email-input"
                  name="emailRegister"
                  placeholder={
                    inputTypeIsEmail ? "user@user.com" : "7xxxxxxxxxx"
                  }
                  minLength={inputTypeIsEmail ? 5 : 11}
                  maxLength={inputTypeIsEmail ? 45 : 11}
                  value={email.value}
                  onChange={email.onChange}
                />
                <span className="form__e-mail-error">{emailErrorMessage}</span>
              </div>
            </div>
            <div className={"auth__button"}>
              <button
                type="button"
                onClick={inputTypeIsEmail ? onSendEmailClick : onSendPhoneClick}
                disabled={disableButton}
              >
                Отправить
              </button>
            </div>
          </div>
          <div className={"main-block__reg"}>
            <div className={"reg__logo"}>
              <img className="reg__logo-main" src={logo} alt="Логотип" />
            </div>
            <div className={"reg__title"}>Система электронных голосований</div>
          </div>
        </div>
      </div>
      <AuthorizationForgetPasswordModal active={modalActive} />
    </div>
  );
};
export default AuthorizationForgetPassword;
