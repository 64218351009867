import React, { useEffect, useRef, useState } from "react";
import "./MyProfilePagePersonalData.css";
import { updateUser } from "../../Api/auth-service";
import { useMutation } from "@tanstack/react-query";

const MyProfilePagePersonalData = (props) => {
  const {
    userId,
    userEmail,
    userFirstName,
    userLastName,
    userSecondName,
    createUserName,
    addCurrentUser,
    typeConfig,
  } = props;
  const [firstName, setFirstName] = useState(userFirstName || "");

  useEffect(() => {
    if (userFirstName && !firstName) setFirstName(userFirstName);
    if (userLastName && !lastName) setLastName(userLastName);
    if (userSecondName && !secondName) setSecondName(userSecondName);
  }, [userFirstName, userLastName, userSecondName]);

  const [lastName, setLastName] = useState(userLastName || "");
  const [secondName, setSecondName] = useState(userSecondName || "");
  const [activeBtn, setActiveBtn] = useState(false);
  const btnChangeColor = useRef(null);
  const borderErrorFirstName = useRef(null);
  const borderErrorLastName = useRef(null);
  const borderErrorSecondName = useRef(null);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validSecondName, setValidSecondName] = useState(false);
  const [errorFields, setErrorFields] = useState("");
  const [activeSuccess, setActiveSuccess] = useState(false);

  useEffect(() => {
    if (typeConfig !== "main") {
      setActiveBtn(true);
    } else {
      if (
        firstName !== userFirstName ||
        lastName !== userLastName ||
        secondName !== userSecondName
      ) {
        setActiveBtn(false);
        btnChangeColor.current.style.background = "#0084FE";
        btnChangeColor.current.style.color = "#FFFFFF";
        btnChangeColor.current.style.cursor = "pointer";
      } else {
        setActiveBtn(true);
        btnChangeColor.current.style.background = "rgba(54, 59, 77, 0.08)";
        btnChangeColor.current.style.color = "rgba(54, 59, 77, 0.35)";
        btnChangeColor.current.style.cursor = "initial";
      }
    }
  }, [firstName, lastName, secondName, typeConfig]);

  const lastNameHandler = (e) => {
    const nameRegExp = /^([а-яё]+|[a-z]+)$/i;
    setLastName(e.target.value);
    if (e.target.value === "") {
      setValidLastName(false);
    } else {
      if (!nameRegExp.test(e.target.value)) {
        setValidLastName(true);
      } else {
        setValidLastName(false);
      }
    }
  };

  const firstNameHandler = (e) => {
    const nameRegExp = /^([а-яё]+|[a-z]+)$/i;
    setFirstName(e.target.value);
    if (e.target.value === "") {
      setValidFirstName(false);
    } else {
      if (!nameRegExp.test(e.target.value)) {
        setValidFirstName(true);
      } else {
        setValidFirstName(false);
      }
    }
  };

  const secondNameHandler = (e) => {
    const nameRegExp = /^([а-яё]+|[a-z]+)$/i;
    setSecondName(e.target.value);
    if (e.target.value === "") {
      setValidSecondName(false);
    } else {
      if (!nameRegExp.test(e.target.value)) {
        setValidSecondName(true);
      } else {
        setValidSecondName(false);
      }
    }
  };

  let itemFields = {
    firstName: firstName,
    lastName: lastName,
    secondName: secondName,
    // userFields: [],
  };

  const updateUserMutation = useMutation({
    mutationFn: (dataForUpdate) => updateUser(dataForUpdate),
    onSuccess: (data) => {
      if (localStorage.getItem("user")) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(data));
        console.log("115 set item user");
        setErrorFields("Данные успешно изменены");
        createUserName(data);
        addCurrentUser(data);
        setActiveBtn(true);
        setActiveSuccess(true);
        borderErrorFirstName.current.style.border = "1px #4ED4A9 solid";
        borderErrorLastName.current.style.border = "1px #4ED4A9 solid";
        borderErrorSecondName.current.style.border = "1px #4ED4A9 solid";
        btnChangeColor.current.style.background = "rgba(54, 59, 77, 0.08)";
        btnChangeColor.current.style.color = "rgba(54, 59, 77, 0.35)";
        btnChangeColor.current.style.cursor = "initial";
        // setFirstName("");
        // setLastName("");
        // setSecondName("");
      }
    },
    onError: (err) => {
      err.message && console.log(err.message);
      setErrorFields("Проверьте данные");
    },
  });

  const updateUserName = () => {
    if (validLastName || validFirstName || validSecondName) {
      setErrorFields("Поля заполнены неккоректно");
      borderErrorFirstName.current.style.border = "1px red solid";
      borderErrorLastName.current.style.border = "1px red solid";
      borderErrorSecondName.current.style.border = "1px red solid";
    } else {
      const body = {
        id: userId,
        ...itemFields,
      };
      updateUserMutation.mutate(body);
    }
  };

  useEffect(() => {
    const successTimeout = setTimeout(() => {
      if (activeSuccess === true) {
        setActiveSuccess(false);
        setErrorFields("");
        borderErrorFirstName.current.style.border =
          "1px rgba(54, 59, 77, 0.3) solid";
        borderErrorLastName.current.style.border =
          "1px rgba(54, 59, 77, 0.3) solid";
        borderErrorSecondName.current.style.border =
          "1px rgba(54, 59, 77, 0.3) solid";
      }
    }, 2000);
    return () => clearTimeout(successTimeout);
  }, [activeSuccess]);

  return (
    <div className={"my-profile-page-personal-data__wrapper"}>
      <h3 className={"my-profile-page-personal-data__title"}>Личные данные</h3>
      <h3 className={"my-profile-page-personal-data__title-mobile"}>ФИО</h3>
      <div className={"my-profile-page-personal-data__form"}>
        <div className={"my-profile-page-personal-data__form-input"}>
          <label>Фамилия</label>
          <input
            type={"text"}
            value={lastName}
            onChange={(e) => lastNameHandler(e)}
            placeholder={userLastName}
            ref={borderErrorLastName}
            disabled={typeConfig !== "main" ? true : null}
          />
        </div>
        <div className={"my-profile-page-personal-data__form-input"}>
          <label>Имя</label>
          <input
            type={"text"}
            value={firstName}
            onChange={(e) => firstNameHandler(e)}
            placeholder={userFirstName}
            ref={borderErrorFirstName}
            disabled={typeConfig !== "main" ? true : null}
          />
        </div>
        <div className={"my-profile-page-personal-data__form-input"}>
          <label>Отчество</label>
          <input
            type={"text"}
            value={secondName}
            onChange={(e) => secondNameHandler(e)}
            placeholder={userSecondName}
            ref={borderErrorSecondName}
            disabled={typeConfig !== "main" ? true : null}
          />
        </div>
        <div
          className={
            "my-profile-page-personal-data__form-input __my-profile-page-personal-date__hidden-e-mail"
          }
        >
          <label>E-mail</label>
          <input disabled={true} type={"email"} placeholder={userEmail} />
        </div>
        <span
          className={
            activeSuccess
              ? "my-profile-page-personal-data__success-message"
              : "my-profile-page-personal-data__error-message"
          }
        >
          {errorFields}
        </span>
      </div>
      <button
        disabled={activeBtn}
        ref={btnChangeColor}
        onClick={() => updateUserName()}
        className={"my-profile-page__save-change-button"}
      >
        Сохранить изменения
      </button>
    </div>
  );
};

export default MyProfilePagePersonalData;
