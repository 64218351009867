import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./AuthorizationSetPassword.css";

import logo from "../../img/Auth_logo_crypto_veche.svg";
import show_pass_icon from "../../img/Auth_show_pass_icon.svg";
import hidden_pass_icon from "../../img/Auth_hidden_pass.svg";
import * as Auth from "../../Api/Auth";
import AuthorizationSetPasswordModal from "../AuthorizationSetPasswordModal/AuthorizationSetPasswordModal";
import { Validation } from "../../utils/Validation";
import rfbrLogo from "../../img/rfbrLogo.svg";import { Link } from "react-router-dom";

const AuthorizationSetPassword = () => {
  const { search, pathname } = useLocation();
  const password = Validation();
  const repeatPassword = Validation();
  const [modalActive, setModalActive] = useState(false);
  const [changeTypePass, setChangeTypePass] = useState("password");
  const [hideBlock, setHideBlock] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const token = search?.split("?code=").slice(-1)[0];
  // useEffect(() => console.log("token: ", token), [token]);

  const [isTokenValid, setIsTokenValid] = useState(false);

  const showHiddenPass = () => {
    if (changeTypePass === "password") {
      setChangeTypePass("text");
    } else {
      setChangeTypePass("password");
    }
  };

  //-------Функция для получения ширины div и затем передаем результат в функцию setNewPassword, которая уже позволяет скрыть блок div толко в мобильной версии-----//
  const getWidthBlock = (width) => {
    width = document.getElementById("container-auth").clientWidth;
    return width;
  };

  useEffect(() => {
    Auth.validateTokenForReset(token).then((res) => {
      if (!res) {
        setPasswordError("Проверьте правильность ссылки. Неверный токен");
        return;
      }

      setIsTokenValid(true);
    });
  }, []);

  function onSaveNewPasswordClick() {
    const passRegExp =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*,.:;+<>{}?\\[\]/_-]{8,64}$/;
    if (password.value === "" || repeatPassword.value === "") {
      setPasswordError("Необходимо заполнить все поля");
    } else if (password.value !== repeatPassword.value) {
      setPasswordError("Пароли не совпадают");
    } else if (passRegExp.test(password.value) === false) {
      setPasswordError(
        "Пароль должен содержать от 8 до 64 символов, состоять из латинских букв верхнего, нижнего регистра и цифр"
      );
    } else {
      setPasswordError("");

      Auth.setNewPassword(token, password.value)
        .then((res) => {
          if (res) {
            setPasswordError("");
            if (getWidthBlock() < 600) {
              setHideBlock(true);
              setModalActive(true);
            } else {
              setModalActive(true);
            }
          } else {
            setPasswordError("Что-то пошло не так, повторите попытку позже");
          }
        })
        .catch((err) => {
          console.error(err.message);
          setPasswordError(err.message);
          //throw new Error(err && err?.message || "Неизвестная ошибка");
        });
    }
  }

  return (
    <div className={"wrapper-auth"}>
      <div id={"container-auth"} className={"container-auth"}>
        <div className={"main-block _modificator-main-block__height"}>
          <div
            className={
              hideBlock
                ? "main-block__auth active _modificator-main-block__auth-set-pass-padding"
                : "main-block__auth _modificator-main-block__auth-set-pass-padding"
            }
          >
            <div className={"auth__title"}>
              <h3>Установка пароля</h3>
              {/* <div>
                <span>РУС</span>
                <span>ENG</span>
              </div> */}
            </div>
            <div className={"auth__form"}>
              <div className={"form__pass"}>
                <span>Новый пароль</span>
                <div className="form__pass-container">
                  <input
                    type={changeTypePass}
                    name="passwordReset"
                    value={password.value}
                    onChange={password.onChange}
                  />

                  <img
                    alt={"иконка показать пароль"}
                    className={"form__pass-show-pass-icon"}
                    src={
                      changeTypePass === "password"
                        ? show_pass_icon
                        : hidden_pass_icon
                    }
                    onClick={showHiddenPass}
                  />
                </div>
              </div>
              <div className={"form__pass"} style={{ marginBottom: 0 }}>
                <span>Повторите новый пароль</span>
                <div className="form__pass-container">
                  <img
                    alt={"иконка показать пароль"}
                    className={"form__pass-show-pass-icon"}
                    src={
                      changeTypePass === "password"
                        ? show_pass_icon
                        : hidden_pass_icon
                    }
                    onClick={showHiddenPass}
                  />
                  <input
                    type={changeTypePass}
                    name="repeatPasswordReset"
                    value={repeatPassword.value}
                    onChange={repeatPassword.onChange}
                  />
                </div>
              </div>
              {passwordError && (
                <p className="form__error" style={{ marginTop: 8 }}>
                  {passwordError}
                </p>
              )}
            </div>

            <div className={"auth__button-save"}>
              <button
                type={"submit"}
                onClick={onSaveNewPasswordClick}
                disabled={!isTokenValid}
              >
                Сохранить
              </button>
            </div>
          </div>
          <div
            className={"main-block__reg _modificator-main-block__reg-padding "}
          >
            <div className={"reg__logo _modificator-reg__logo-padding-bottom"}>
              <img src={rfbrLogo} alt="rfbr" />
              <img className="reg__logo-main" src={logo} alt="Логотип" />
            </div>
            <div className={"reg__title _modificator-reg__title-set-pass-top"}>
              Система электронных голосований
            </div>
          </div>
        </div>
      </div>
      <AuthorizationSetPasswordModal active={modalActive} />
    </div>
  );
};

export default AuthorizationSetPassword;
