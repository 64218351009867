import axios from "axios";
import { config } from "../config";

const authService = axios.create({
  baseURL: config.auth_api_url,
  headers: {
    "Content-type": "application/json",
  },
});

authService.interceptors.request.use((axiosConfig) => {
  // Do something before request is sent
  let token = localStorage.getItem("accessToken");
  if (token) {
    axiosConfig.headers["Authorization"] = "Bearer " + token;
  }
  return axiosConfig;
});

/********************************/

export const updateUser = async (dataForUpdate) => {
  return await authService
    .patch(`/users/`, JSON.stringify(dataForUpdate))
    .then((resp) => {
      console.log(resp.data); // good data here
      return resp.data; // and returned
    });
};
