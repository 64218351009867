import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import useScrollTop from "../hooks/useScrollTop";
const Layout = ({
  handleLogout,
  userName,
  allEvents,
  handleCurrentEvents,
  handleReloadDetailsPage,
}) => {
  useScrollTop();

  return (
    <>
      <Header
        handleLogout={handleLogout}
        userName={userName}
        allEvents={allEvents}
        handleCurrentEvents={handleCurrentEvents}
        handleReloadDetailsPage={handleReloadDetailsPage}
      />
      <main className="main">
        <div className="main-content _container">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
