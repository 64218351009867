import React, { useState } from "react";
import "./DetailsVotesPageResultQuestionGrid.css";
import DetailsVotesPageResultGridTable from "../DetailsVotesPageResultGridTable/DetailsVotesPageResultGridTable";
import DetailsVotesPageResultGridTable2 from "../DetailsVotesPageResultGridTable/DetailsVotesPageResultGridTable2";

import DetailsVotesPageResultVotesCardQuestionGraphGrid from "../DetailsVotesPageResultVotesCardQuestionGraph/DetailsVotesPageResultVotesCardQuestionGraphGrid";

const DetailsVotesPageResultQuestionGrid = (props) => {
  const { question } = props;

  const isQuestionWithPoints = !question.answers[0].columns.find((answer) =>
    isNaN(+answer.value)
  );

  const [graphResult, setGraphResult] = useState(false);
  const [tableResult, setTableResult] = useState(!isQuestionWithPoints);
  const [table2Result, setTable2Result] = useState(isQuestionWithPoints);

  function toggleGraphShow() {
    setGraphResult(true);
    setTableResult(false);
    setTable2Result(false);
  }

  function toggleTableShow() {
    setTableResult(true);
    setGraphResult(false);
    setTable2Result(false);
  }

  function toggleTable2Show() {
    setTable2Result(true);
    setGraphResult(false);
    setTableResult(false);
  }

  return (
    <div className="details-votes-page-result-question-grid">
      <div className="details-votes-page-result-question-grid__title-container">
        <h3 className="details-votes-page-result-question-grid__title">
          {question.title}
        </h3>
        <h5 className="details-votes-page-result-question-grid__rule">
          Выберите один из вариантов ответа
        </h5>
      </div>
      <div className="details-votes-page-result-votes-card__switch-table-gistogramma">
        <div className="tooltip">
          <div
            onClick={toggleGraphShow}
            className={
              graphResult
                ? "switch-table-gistogramma__gistogramma active"
                : "switch-table-gistogramma__gistogramma"
            }
          ></div>
          <span className="tooltiptext">Показать графиком</span>
        </div>
        <div className="tooltip">
          <div
            onClick={toggleTableShow}
            className={
              tableResult
                ? "switch-table-gistogramma__table active"
                : "switch-table-gistogramma__table"
            }
          ></div>
          <span className="tooltiptext">Показать таблицей</span>
        </div>

        {isQuestionWithPoints && (
          <div className="tooltip">
            <div
              onClick={toggleTable2Show}
              className={
                table2Result
                  ? "switch-table-gistogramma__table2 active"
                  : "switch-table-gistogramma__table2"
              }
            ></div>
            <span className="tooltiptext">Показать таблицей результатов</span>
          </div>
        )}
      </div>
      {tableResult && (
        <div className="details-votes-page-result-question-grid__tables-container">
          {question.answers.map((answer) => (
            <DetailsVotesPageResultGridTable key={answer.id} answer={answer} />
          ))}
        </div>
      )}

      {table2Result && (
        <DetailsVotesPageResultGridTable2
          answers={question.answers}
          question={question}
        />
      )}

      {graphResult && (
        <DetailsVotesPageResultVotesCardQuestionGraphGrid
          answersTemplateGrid={question.answers}
        />
      )}
    </div>
  );
};
export default DetailsVotesPageResultQuestionGrid;
