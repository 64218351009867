import React from "react";
import "./CardQuestionVerticalGraphGrid.css";
import CardQuestionVerticalGraphThinColumn from "../CardQuestionVerticalGraphThinColumn/CardQuestionVerticalGraphThinColumn";

const CardQuestionVerticalGraphGrid = (props) => {
  const { resultVote, getNameColumnColor } = props;

  console.log("111", resultVote);

  return (
    <div className={"card-question-vertical-graph-grid__vertical-grid"}>
      <table className={"vertical-grid__position-table-grid"}>
        <tbody>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
      {resultVote.map((el) => {
        return (
          <CardQuestionVerticalGraphThinColumn
            key={el.id}
            nameAnswer={el.title}
            result={el.columns}
            colorColumn={getNameColumnColor.map((el) => el.color)}
            allVotes={el.columns.reduce(
              (accumulator, it) => accumulator + it.favor,
              0
            )}
          />
        );
      })}
    </div>
  );
};
export default CardQuestionVerticalGraphGrid;
