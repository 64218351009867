import axios from "axios";
import { config } from "../config";

const apiService = axios.create({
  baseURL: config.java_api_url,
  headers: {
    "Content-type": "application/json",
  },
});

apiService.interceptors.request.use((axiosConfig) => {
  // Do something before request is sent
  let token = localStorage.getItem("accessToken");
  if (token) {
    axiosConfig.headers["Authorization"] = "Bearer " + token;
  }
  return axiosConfig;
});

/********************************/

export const getEvents = async () =>
  await apiService.get("/events/me").then((resp) => {
    console.log("resp: ", resp);
    if (resp.data.status === "failure") {
      console.error(resp.data.text);
    }
    return resp.data;
  });
export const getEventById = async (id) =>
  await apiService.get(`/events/me/${id}`).then((resp) => resp.data);

export const getStats = async () =>
  await apiService.get("/common_statistic").then((resp) => resp.data);

export const vote = async (body) => {
  await apiService
    .put(`/events/vote/${body.eventId}`, JSON.stringify(body.eventArray))
    .then((resp) => resp.data);
};

export const joinEventByLink = async (id) =>
  await apiService.get(`/events/join/${id}`).then((resp) => resp.data);

export const registrationUserInEvents = async (id) =>
  await apiService
    .put(`/events/toggle_register/${id}`)
    .then((resp) => resp.data);
