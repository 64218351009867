import "./App.css";
import "../Authorization/Authorization";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { config } from "../../config";
import Layout from "../Layout";
import Authorization from "../Authorization/Authorization";
import Registration from "../Registration/Registration";
import AuthorizationForgetPassword from "../AuthorizationForgetPassword/AuthorizationForgetPassword";
import AuthorizationSetPassword from "../AuthorizationSetPassword/AuthorizationSetPassword";
import VotesPage from "../VotesPage/VotesPage";
import MainPage from "../MainPage/MainPage";
import CallVotingPage from "../CallVotingPage/CallVotingPage";
import MyProfilePage from "../MyProfilePage/MyProfilePage";
import DetailsVotesPage from "../DetailsVotesPage/DetailsVotesPage";
import VotesPageSuccessRegLaterModal from "../VotesPageSuccessRegLaterModal/VotesPageSuccessRegLaterModal";
import PreLoaderCallVotingPage from "../PreLoaderCallVotingPage/PreLoaderCallVotingPage";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import timeZone from "../../utils/TimeZoneData/TimeZoneRu.json";
import * as Auth from "../../Api/Auth";
import * as Events from "../../Api/Events";
import { NoMatchRoutes } from "../../utils/NoMatchRoutes/NoMatchRoutes";
import { Stomp } from "@stomp/stompjs";
import { useLoginByCode } from "../../hooks/useLoginByCode";
import { useLocalStorageVersion } from "../../hooks/useLocalStorageVersion";
import { useEvents } from "../../fetchers/useEvents";
import { useEventById } from "../../fetchers/useEventById";
import { queryClient } from "../..";
import Loader from "../Loader/Loader";
import {
  joinEventByLink,
  registrationUserInEvents,
} from "../../Api/api-service";
import { useQuery, useMutation } from "@tanstack/react-query";
import ProtectedRoute from "../ProtectedRoute";
function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [authError, setAuthError] = useState("");
  const [userName, setUserName] = useState("");
  const [isPolicyAccept, setPolicyAccept] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [changeBorderInputEmail, setChangeBorderInputEmail] = useState(
    "_input-border-black-reg-page"
  );
  const [hideRegForm, setHideRegForm] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [isSuccessModalActive, setSuccessModalActive] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [isResultTabOpen, setResultTabOpen] = useState(false);
  const [utcOffset, setUtcOffset] = useState("");
  const [changeUtcOffset, setChangeUtcOffset] = useState("");
  const [preLoaderAuthBtn, setPreloaderAuthBtn] = useState(false);
  const [preLoaderRegBtn, setPreLoaderRegBtn] = useState(false);
  const [joinId, setJoinId] = useState("");
  const [isReloadDetailsPage, setReloadDetailsPage] = useState(false);
  const [eventWaitingIdByLink, setEventWaitingIdByLink] = useState("");
  const [eventQuestionsIdByLink, setEventQuestionsIdByLink] = useState("");
  const [eventResultIdByLink, setEventResultIdByLink] = useState("");
  const [isReloadPage, setReloadPage] = useState(false);
  const [isSignedEventId, setSignedEventId] = useState("");
  const [isPreloaderActive, setPreloaderActive] = useState(false);

  const [newEventId, setNewEventId] = useState("");

  const [statsReload, setStatsReload] = useState(false);
  const [loginByCode, setLoginByCode] = useState(false);

  const [isStatisticsUpdate, setIsStatisticsUpdate] = useState(false);

  const [firstSubscribe, setFirstSubscribe] = useState(true);

  const [wsClient, setWsClient] = useState(null);

  const location = useLocation();

  const { isFetching: isJoinEventLoading } = useQuery({
    queryKey: ["joinEventByLink", joinId],
    queryFn: () => joinEventByLink(joinId),
    refetchOnMount: false,
    enabled: !!joinId && isLoggedIn,
    onSuccess: (data) => {
      const body = {
        id: joinId,
      };
      setJoinId("");
      setSuccessModalText("Вы были успешно добавлены к голосованию");
      handleCurrentEvents(body, true);
      handleShowSuccessModal();

      subscribeToEvent(joinId, config);

      const oldEvents = localStorage.getItem("events");

      if (
        oldEvents &&
        !JSON.parse(oldEvents).find((event) => event.id === joinId)
      ) {
        events.refetch().then((newEvents) => {
          localStorage.setItem("events", JSON.stringify(newEvents.data));
        });
      }

      if (data && data?.text.includes("Not found")) {
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }

      if (data && data?.text === "User has already joined") {
        handleShowSuccessModal();
        setSuccessModalText("Вы уже присоединились к данному голосованию");
        handleCurrentEvents(body, true);
      }

      if (data && data?.text === "Registration is over") {
        events
          .refetch()
          .then((data) => {
            const foundedEvent = data.find((event) => event.id === joinId);
            handleShowSuccessModal();
            if (foundedEvent) {
              setSuccessModalText(
                "Вы уже присоединились к данному голосованию"
              );
              handleCurrentEvents(body, true);
            } else {
              setSuccessModalText(
                "Вы не можете быть добавлены, так как регистрация завершена или событие окончено"
              );
            }
          })
          .catch((err) => {
            throw new Error(err?.message || "Неизвестная ошибка");
          });
      }
    },
    onError: () => {
      setJoinId("");

      handleShowSuccessModal();
      setSuccessModalText("Достигнут лимит участников голосования");
    },
  });

  useEffect(() => {
    if (location.pathname.includes("join/")) {
      const id = location.pathname.split("join/")[1];

      if (id) {
        setJoinId(id);
        console.log("вошел в set join ID!");
        if (!isLoggedIn) {
          navigate("/auth");
        }
      }
    }
  }, [location.pathname]);

  const events = useEvents({
    enabled: false,
    refetchOnMount: true,
    onSuccess: (data) => {
      // console.log("events success");
      // console.log("data: ", data);
      //console.log("firstSub: ", firstSubscribe);
      // if (firstSubscribe && wsClient) {
      //   localStorage.setItem("events", JSON.stringify(data));
      //   firstSubscribeToEvents(data);
      //   if (localStorage.getItem("user")) {
      //     const user = JSON.parse(localStorage.getItem("user"));
      //     const subUserId = `${user.authorities[0].role}${user.id}`;
      //     subscribeToNewEvents(subUserId, config);
      //   }
      //   setFirstSubscribe(false);
      // }
    },
  });

  useLocalStorageVersion();

  function requestHelper(request, body = {}) {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("accessToken")) {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        request(accessToken, body)
          .then((res) => {
            if (res.status && res.status === "failure") {
              // return;
              Auth.login({ type: "refresh-token", token: refreshToken })
                .then((newTokens) => {
                  if (!newTokens.ok) {
                    return;
                    setTimeout(() => {
                      window.location.reload();
                    }, 10000);
                    return;
                    logout();
                  } else {
                    // // const { accessToken, refreshToken } = JSON.stringify(newTokens);
                    // console.log("new tokens: ", newTokens.json());
                    // localStorage.setItem("accessToken", accessToken);
                    // localStorage.setItem("refreshToken", refreshToken);

                    request(accessToken, body)
                      .then((res) => {
                        resolve(res);
                      })
                      .catch((err) => {
                        throw new Error(err.message);
                      });
                  }
                })
                .catch((err) => {
                  if (err.message) {
                    throw new Error(err.message);
                  }
                });
            } else {
              resolve(res);
            }
          })
          .catch((err) => {
            disactivatePreloader();
            if (err.message) {
              throw new Error(err.message);
            }
          });
      } else {
        logout();
      }
    });
  }

  const loginByCodeHandler = async (code) => {
    const loginResponse = await Auth.login({
      token: code,
      type: `authorization-token`,
    });

    if (loginResponse?.status === 400) {
      setAuthError("Пользователь не найден");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status === 401) {
      setAuthError("Неверный логин или пароль");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status) {
      setAuthError(`Ошибка сервера: ${loginResponse.status}`);
      return;
    }

    const userData = await Auth.getMe(loginResponse.accessToken);

    if (loginResponse.accessToken && loginResponse.refreshToken) {
      localStorage.setItem("accessToken", loginResponse.accessToken);
      localStorage.setItem("refreshToken", loginResponse.refreshToken);
    }

    localStorage.setItem("user", JSON.stringify(userData));
    console.log("295 set item user");
    setLoggedIn(true);

    queryClient.invalidateQueries({ queryKey: ["events"] });

    addCurrentUser(userData);
    createUserName(userData);
    setOffset(userData.utcOffset);

    navigate(location.pathname);
  };

  const code =
    location.search.includes("?code=") &&
    !location.pathname.includes("rstpwd") &&
    !location.pathname.includes("reset-password")
      ? location.search.replace("?code=", "")
      : null;

  useEffect(() => {
    if (
      !isLoggedIn &&
      code &&
      !location.pathname.includes("rstpwd") &&
      !location.pathname.includes("reset-password")
    ) {
      setLoginByCode(true);
      loginByCodeHandler(code);

      const currentEvent = {
        id: location.pathname.replace("/details-vote/", ""),
      };

      if (localStorage.getItem("currentEvent")) {
        localStorage.removeItem("currentEvent");
        localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
      } else {
        localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
      }

      //navigate(`../details-vote/${currentEvent.id}`, { replace: true });

      // navigate("my-profile");
    }
  }, []);

  useEffect(() => {
    if (pathname === "/auth" && isLoggedIn && !loginByCode) {
      navigate("/");
      window.scrollTo(0, 0);
    } else if (pathname === "/auth" && !isLoggedIn && !loginByCode) {
      navigate("/auth");
    }
  }, [pathname, isLoggedIn, navigate]);

  function handleReloadPage() {
    setReloadPage(false);
  }

  function handleCleanIsSignedEventId() {
    setSignedEventId("");
  }

  function activatePreloader() {
    setPreloaderActive(true);
  }

  function disactivatePreloader() {
    setPreloaderActive(false);
  }

  useEffect(() => {
    if (!wsClient) {
      setWsClient(Stomp.client(config.ws_connect));
    }

    if (wsClient && isLoggedIn && firstSubscribe) {
      wsClient.reconnectDelay = 5000;
      // логирование подписок
      // wsClient.debug = (str) => {
      //   console.warn(str);
      // };
      wsClient.debug = (str) => {};

      wsClient.connect(
        config.ws_user,
        config.ws_pass,
        () => {
          console.log("connected");
          events.refetch().then((newEvents) => {
            localStorage.setItem("events", JSON.stringify(newEvents.data));
            firstSubscribeToEvents(newEvents.data);

            if (localStorage.getItem("user")) {
              const user = JSON.parse(localStorage.getItem("user"));

              const subUserId = `${user.authorities[0].role}${user.id}`;
              subscribeToNewEvents(subUserId, config);
            }
            setFirstSubscribe(false);
          });
        },
        () => console.error("error connect"),
        "/"
      );

      wsClient.onWebSocketClose = function () {};
    }
  }, [wsClient, events, events.isSuccess, isLoggedIn]);

  function handleMessage(message) {
    console.log("message from handle body: ", message.body);

    setSignedEventId(message.body);
    setReloadPage(true);
    // поудалять лишнее

    const oldEvents = localStorage.getItem("events");

    if (!oldEvents) {
      queryClient.invalidateQueries({
        queryKey: ["events"],
      });
      return;
    }

    events.refetch().then((newEvents) => {
      console.log("events refetch 445 app");
      // если новое голосование
      if (
        oldEvents &&
        !JSON.parse(oldEvents).find((event) => event.id === message.body)
      ) {
        //console.log("Old events:", oldEvents);
        console.log("message body:", message.body);
        // console.log("NEW EVENTS:", newEvents.data);
        // refactoring
        handleShowSuccessModal();
        console.log("Вы были добавлены в новое голосование!");
        setSuccessModalText("Вы были добавлены в новое голосование!");
        console.warn(message.body);
        setNewEventId(message.body);
        setStatsReload(true); // чтобы скачать заново
        subscribeToEvent(message.body, config);
        //
        localStorage.setItem("events", JSON.stringify(newEvents.data));
      }
    });

    queryClient.invalidateQueries({
      queryKey: ["events", { id: message.body }],
    });
  }

  function handleMessageDelete() {
    queryClient.invalidateQueries({ queryKey: ["events"] });
  }

  function handleStatisticsMessage() {
    setIsStatisticsUpdate(true);
  }

  function subscribeToEvent(eventId) {
    console.warn(`! подписался на событие Event ID: `, eventId);

    const subIdTime = wsClient.subscribe(
      `/exchange/${eventId}/time`,
      handleMessage,
      {}
    );
    console.log("subIdTime: ", subIdTime);

    const subIdDelete = wsClient.subscribe(
      `/exchange/${eventId}/delete`,
      handleMessageDelete,
      {}
    );
    console.log("subIdDelete: ", subIdDelete);

    const subIdStatistics = wsClient.subscribe(
      `/exchange/${eventId}/event_statistics`,
      handleStatisticsMessage,
      {}
    );

    console.log("subIdStatistics: ", subIdStatistics);
  }

  function subscribeToNewEvents(userId) {
    if (wsClient) {
      console.warn(`! подписался на новые Events юзер: `, userId);

      const subUser = wsClient.subscribe(
        `/exchange/events/${userId}`,
        handleMessage,
        {}
      );

      console.log("subUser: ", subUser);
    }
  }

  // useEffect(() => console.log(wsClient), [wsClient]);

  function firstSubscribeToEvents(events) {
    console.warn("first Subscribe To Events");
    console.warn("events in first sub: ", events);

    if (events && Array.isArray(events) && events.length !== 0) {
      events.forEach((event) => {
        if (event.status !== "ended") {
          if (event.status !== "quorum_unpresant") {
            console.log("first sub event id: ", event.id);
            subscribeToEvent(event.id, config);
            // console.log("subscribeToEvent 373");
          }
        }
      });
    }
  }

  // ToDo понять зачем это и надо ли?
  useEffect(() => {
    const url = window.location.href.split("/");
    if (isLoggedIn) {
      // if (
      //   pathname === "/" ||
      //   pathname === "/votes-page" ||
      //   pathname === "/my-profile" ||
      //   pathname.includes("/details-vote")
      // ) {
      //   console.log("607")
      //   queryClient.invalidateQueries({ queryKey: ["events"] });
      // } else

      if (url[3] === "waiting") {
        if (url[4] !== undefined) {
          const data = {
            id: url[4],
          };
          handleCurrentEvents(data, true);
        } else {
          navigate("/");
        }
      } else if (url[3] === "questions") {
        if (url[4] !== undefined) {
          const data = {
            id: url[4],
          };

          requestHelper(Events.getEvent, data)
            .then((res) => {
              console.log("get Event 534 App");
              if (res.status === "ended" || res.status === "quorum_unpresant") {
                handleCurrentEvents(data, true);
              } else {
                if (res.isRegistered) {
                  handleCurrentEvents(data, false);
                } else {
                  handleCurrentEvents(data, true);
                }
              }
            })
            .catch((err) => {
              throw new Error(err?.message || "Неизвестная ошибка");
            });
        } else {
          navigate("/");
        }
      } else if (url[3] === "results") {
        if (url[4] !== undefined) {
          const data = {
            id: url[4],
          };
          showEventResult(data);
        } else {
          navigate("/");
        }
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  function hideRegisterModal() {
    setModalActive(false);
    setPreLoaderRegBtn(false);
  }

  function handlePolicyAccept() {
    if (isPolicyAccept) {
      setPolicyAccept(false);
    } else {
      setPolicyAccept(true);
    }
  }

  const userDefaultName = {
    lastName: "Неизвестный",
    firstName: "Пользователь",
  };

  function createUserName(user) {
    const firstName = function () {
      if (!user.firstName) {
        return `${userDefaultName.firstName.charAt(0)}`;
      } else {
        return `${user.firstName.charAt(0)}`;
      }
    };
    const lastName = function () {
      if (!user.lastName) {
        return userDefaultName.lastName;
      } else {
        return user.lastName;
      }
    };
    const middleName = function () {
      if (!user.secondName) {
        return "";
      } else {
        return `${user.secondName.charAt(0)}.`;
      }
    };
    const shortName = `${lastName()} ${firstName()}.${middleName()}`;
    setUserName(shortName);
  }

  function logout() {
    if (localStorage.getItem("user")) {
      localStorage.removeItem("user");
    }
    // if (localStorage.getItem("jwt")) {
    //   localStorage.removeItem("jwt");
    // }
    if (localStorage.getItem("accessToken")) {
      localStorage.removeItem("accessToken");
    }
    if (localStorage.getItem("refreshToken")) {
      localStorage.removeItem("refreshToken");
    }
    if (localStorage.getItem("currentEvent")) {
      localStorage.removeItem("currentEvent");
    }
    if (localStorage.getItem("events")) {
      localStorage.removeItem("events");
    }

    if (localStorage.getItem("eventList")) {
      localStorage.removeItem("eventList");
    }

    if (localStorage.getItem("statsData")) {
      localStorage.removeItem("statsData");
    }

    if (wsClient) {
      wsClient.disconnect();
    }

    // queryClient.invalidateQueries({
    //   queryKey: ["events"],
    // });

    queryClient.cancelQueries({ queryKey: ["events"] });

    setFirstSubscribe(true);
    setLoggedIn(false);
    setCurrentUser({});
    setUtcOffset("");
    navigate("/auth");
    setPreloaderAuthBtn(false);
    setPreLoaderRegBtn(false);

    setSuccessModalActive(false);

    setLoginByCode(false);
  }

  function setOffset(utc) {
    if (utc !== undefined) {
      const localOffset = timeZone.find(
        (value) => value.VALUE === utc.toString()
      );
      setUtcOffset(localOffset.LABEL);
      setChangeUtcOffset(localOffset.VALUE);
    } else {
      console.log("");
    }
  }

  function addCurrentUser(user) {
    setCurrentUser(user);
  }

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const userData = localStorage.getItem("user");
      const user = JSON.parse(userData);
      addCurrentUser(user);
      createUserName(user);
      setPreloaderAuthBtn(false);
      setLoggedIn(true);
      setOffset(user.utcOffset);

      // const subUserId = `${user.authorities[0].role}${user.id}`;
      // subscribeToNewEvents(subUserId, config); 5 октября
      if (
        pathname === "/auth" ||
        pathname.includes("/rstpwd") ||
        pathname.includes("/reset-password") ||
        pathname === "/registration" ||
        pathname === "/reg-second-page"
      ) {
        navigate("/");
      } else if (pathname === "/forget") {
        navigate("/my-profile");
      }
    } else {
      const url = window.location.href.split("/");

      if (
        pathname === "/" ||
        pathname.includes("/call-voting-page") ||
        pathname === "/my-profile" ||
        pathname.includes("/details-vote") ||
        pathname === "/result-vote" ||
        pathname === "/votes-page"
      ) {
        const code = location.search.includes("?code=");

        !code && logout();
      } else if (url[3] === "waiting") {
        if (url[4] !== undefined) {
          setEventWaitingIdByLink(url[4]);
          logout();
        } else {
          logout();
        }
      } else if (url[3] === "questions") {
        if (url[4] !== undefined) {
          setEventQuestionsIdByLink(url[4]);
          logout();
        } else {
          logout();
        }
      } else if (url[3] === "results") {
        if (url[4] !== undefined) {
          setEventResultIdByLink(url[4]);
          logout();
        } else {
          logout();
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  function hideRegEmailErrors() {
    setEmailErrorMessage("");
    setChangeBorderInputEmail("_input-border-black-reg-page");
  }

  function handleRegister(registerData) {
    if (isPolicyAccept) {
      if (registerData.isRegistrationByToken) {
        Auth.registrationUserByToken(registerData)
          .then((res) => {
            if (res.status === "ok") {
              setModalActive(true);
              hideRegEmailErrors();
              setHideRegForm(true);
            }
          })
          .catch((err) => {
            //throw new Error(err?.message || "Неизвестная ошибка");
          });
      } else {
        Auth.registration(registerData)
          .then((res) => {
            if (res && res.ok) {
              setEmailErrorMessage("");
              setModalActive(true);
              hideRegEmailErrors();
              setHideRegForm(true);
            } else {
              setChangeBorderInputEmail("_input-border-red");
              const data = res.json();
              setEmailErrorMessage(data.message || "Ошибка регистрации");
            }
          })
          .catch((err) => {
            console.log(err);
            //throw new Error(err?.message || "Неизвестная ошибка");
          });
      }
    } else {
      handleShowSuccessModal();
      setSuccessModalText("Необходимо отметить ознакомление с политикой");
    }
  }

  function handleReloadDetailsPage() {
    if (pathname.includes("/details-vote")) {
      if (isReloadDetailsPage) {
        setReloadDetailsPage(false);
      } else {
        setReloadDetailsPage(true);
      }
    }
  }

  const eventRegistration = useMutation({
    mutationFn: (id) => registrationUserInEvents(id),
  });

  const toggleEventRegistration = (eventId, isRegistered, skipRegistration) => {
    eventRegistration.mutate(eventId, {
      onSuccess: () => {
        if (skipRegistration) {
          handleCurrentEvents({ id: eventId }, false);
          return;
        }

        queryClient.invalidateQueries({
          queryKey: ["events"],
        });
        queryClient.invalidateQueries({
          queryKey: ["events", { id: eventId }],
        });

        if (!isRegistered) {
          handleShowSuccessModal();
          setSuccessModalText("Вы успешно зарегистрировались!");
        } else {
          handleShowSuccessModal();
          setSuccessModalText("Вы успешно отменили регистрацию!");
        }
        handleReloadDetailsPage();
      },
    });

    // if (skipRegistration) {
    //   requestHelper(Events.registrationUserInEvents, body)
    //     .then((data) => {
    //       if (data.status === "ok") {
    //         handleCurrentEvents(body, false);
    //       }
    //     })
    //     .catch((err) => {
    //       throw new Error(err?.message || "Неизвестная ошибка");
    //     });
    // } else {
    //   requestHelper(Events.registrationUserInEvents, body)
    //     .then((data) => {
    //       if (data.status === "ok") {
    //         queryClient.invalidateQueries({
    //           queryKey: ["events"],
    //         });
    //         queryClient.invalidateQueries({
    //           queryKey: ["events", { id: body.id }],
    //         });

    //         if (!isRegistered) {
    //           handleShowSuccessModal();
    //           setSuccessModalText("Вы успешно зарегистрировались!");
    //         } else {
    //           handleShowSuccessModal();
    //           setSuccessModalText("Вы успешно отменили регистрацию!");
    //         }
    //         handleReloadDetailsPage();
    //       }
    //     })
    //     .catch((err) => {
    //       throw new Error(err?.message || "Неизвестная ошибка");
    //     });
    // }
  };

  function handleCurrentEvents(data, isDetailsClick) {
    const currentEvent = {
      id: data.id,
    };
    if (localStorage.getItem("currentEvent")) {
      localStorage.removeItem("currentEvent");
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    } else {
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    }
    if (isDetailsClick) {
      navigate(`/details-vote/${data.id}`);
      setEventWaitingIdByLink("");
      setEventQuestionsIdByLink("");
    } else {
      navigate(`/call-voting-page/${data.id}`);
      setEventQuestionsIdByLink("");
    }
  }

  function handleResultTabOpen() {
    setResultTabOpen(false);
  }

  function showEventResult(data) {
    const currentEvent = {
      id: data.id,
    };
    if (localStorage.getItem("currentEvent")) {
      localStorage.removeItem("currentEvent");
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    } else {
      localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
    }
    setResultTabOpen(true);
    navigate(`/details-vote/${data.id}`);
    setEventResultIdByLink("");
  }

  function handleShowSuccessModal() {
    if (isSuccessModalActive) {
      setSuccessModalActive(false);
    } else {
      setSuccessModalActive(true);
    }
  }

  function formatDate(serverDate) {
    const localDate = new Date(serverDate.toString());
    const defaultDate = localDate.getDate();
    const date = `${
      defaultDate.toString().length === 1
        ? `${"0" + defaultDate}`
        : `${defaultDate}`
    }`;
    const defaultMonth = localDate.getMonth() + 1;
    const month = `${
      defaultMonth.toString().length === 1
        ? `${"0" + defaultMonth}`
        : `${defaultMonth}`
    }`;
    const year = localDate.getFullYear();
    return `${date + "." + month + "." + year}`;
  }

  function formatTime(serverDate) {
    const localDate = new Date(serverDate);
    const currentDate = new Date();
    const getUtsCurrent = currentDate.getTimezoneOffset();
    const localDateUtc = localDate.getTimezoneOffset();
    if (localDateUtc !== Number(changeUtcOffset * -60)) {
      const serverOffsetMillis = 60 * 1000 * Number(changeUtcOffset * -60);
      const sumGetUtccurrent = 60 * 1000 * getUtsCurrent;
      const localOffset = new Date(
        localDate.getTime() - serverOffsetMillis + sumGetUtccurrent
      );
      const defaultHours = localOffset.getHours();
      const hoursChangeUtc = `${
        defaultHours.toString().length === 1
          ? `${"0" + defaultHours}`
          : `${defaultHours}`
      }`;
      const defaultMinutes = localDate.getMinutes();
      const minutes = `${
        defaultMinutes.toString().length === 1
          ? `${"0" + defaultMinutes}`
          : `${defaultMinutes}`
      }`;
      return `${hoursChangeUtc + ":" + minutes}`;
    } else {
      const defaultHours = localDate.getHours();
      const hours = `${
        defaultHours.toString().length === 1
          ? `${"0" + defaultHours}`
          : `${defaultHours}`
      }`;
      const defaultMinutes = localDate.getMinutes();
      const minutes = `${
        defaultMinutes.toString().length === 1
          ? `${"0" + defaultMinutes}`
          : `${defaultMinutes}`
      }`;
      return `${hours + ":" + minutes}`;
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="App">
        {isPreloaderActive && <PreLoaderCallVotingPage />}

        <Routes>
          <Route path="/rstpwd" element={<AuthorizationSetPassword />} />
          <Route
            path={`reset-password`}
            element={<AuthorizationSetPassword />}
          />
          <Route
            path="/auth"
            element={
              <Authorization
                authError={authError}
                preLoaderBtn={preLoaderAuthBtn}
                config={config}
                setAuthError={setAuthError}
                requestHelper={requestHelper}
                setLoggedIn={setLoggedIn}
                addCurrentUser={addCurrentUser}
                createUserName={createUserName}
                setOffset={setOffset}
                eventWaitingIdByLink={eventWaitingIdByLink}
                eventQuestionsIdByLink={eventQuestionsIdByLink}
                handleCurrentEvents={handleCurrentEvents}
                eventResultIdByLink={eventResultIdByLink}
                showEventResult={showEventResult}
              />
            }
          />
          <Route
            exact
            path="/registration"
            element={
              <Registration
                requestHelper={requestHelper}
                handleRegister={handleRegister}
                handlePolicyAccept={handlePolicyAccept}
                isPolicyAccept={isPolicyAccept}
                modalActive={modalActive}
                emailErrorMessage={emailErrorMessage}
                setEmailErrorMessage={setEmailErrorMessage}
                changeBorderInputEmail={changeBorderInputEmail}
                hideRegisterModal={hideRegisterModal}
                hideRegForm={hideRegForm}
                hideRegEmailErrors={hideRegEmailErrors}
                preLoaderReg={preLoaderRegBtn}
                config={config}
              />
            }
          />
          <Route
            path="/forget"
            element={
              <AuthorizationForgetPassword requestHelper={requestHelper} />
            }
          />
          <Route
            element={
              <Layout
                handleLogout={logout}
                userName={userName}
                allEvents={allEvents}
                handleCurrentEvents={handleCurrentEvents}
                handleReloadDetailsPage={handleReloadDetailsPage}
              />
            }
            errorElement={<NoMatchRoutes />}
          >
            {/* <Route
                path="/registration/:token"
                element={
                  <Registration
                    handleRegister={handleRegister}
                    handlePolicyAccept={handlePolicyAccept}
                    isPolicyAccept={isPolicyAccept}
                    modalActive={modalActive}
                    emailErrorMessage={emailErrorMessage}
                    changeBorderInputEmail={changeBorderInputEmail}
                    hideRegisterModal={hideRegisterModal}
                    hideRegForm={hideRegForm}
                    hideRegEmailErrors={hideRegEmailErrors}
                    preLoaderReg={preLoaderRegBtn}
                    config={config}
                  />
                }
              /> */}
            <Route
              exact
              path="/"
              element={
                <MainPage
                  allEvents={allEvents}
                  requestHelper={requestHelper}
                  handleCurrentEvents={handleCurrentEvents}
                  toggleEventRegistration={toggleEventRegistration}
                  showEventResult={showEventResult}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                  isLoggedIn={isLoggedIn}
                  statsReload={statsReload}
                  setStatsReload={setStatsReload}
                  firstSubscribe={firstSubscribe}
                  isJoinEventLoading={isJoinEventLoading}
                />
              }
            />
            <Route
              exact
              path="/call-voting-page/:id"
              element={
                <CallVotingPage
                  requestHelper={requestHelper}
                  handleCurrentEvents={handleCurrentEvents}
                  handleReloadPage={handleReloadPage}
                  isReloadPage={isReloadPage}
                  isSignedEventId={isSignedEventId}
                />
              }
            />
            <Route
              exact
              path="/my-profile"
              element={
                <MyProfilePage
                  requestHelper={requestHelper}
                  utcOffset={utcOffset}
                  allEvents={allEvents}
                  createUserName={createUserName}
                  setOffset={setOffset}
                  handleLogout={logout}
                  formatTime={formatTime}
                  formatDate={formatDate}
                  addCurrentUser={addCurrentUser}
                />
              }
            />
            <Route
              path={`/details-vote/:id`}
              element={
                <DetailsVotesPage
                  requestHelper={requestHelper}
                  handleCurrentEvents={handleCurrentEvents}
                  toggleEventRegistration={toggleEventRegistration}
                  showEventResult={showEventResult}
                  isResultTabOpen={isResultTabOpen}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                  handleResultTabOpen={handleResultTabOpen}
                  isReloadDetailsPage={isReloadDetailsPage}
                  handleReloadDetailsPage={handleReloadDetailsPage}
                  handleReloadPage={handleReloadPage}
                  isReloadPage={isReloadPage}
                  activatePreloader={activatePreloader}
                  disactivatePreloader={disactivatePreloader}
                  isSignedEventId={isSignedEventId}
                  loginByCode={loginByCode}
                  isStatisticsUpdate={isStatisticsUpdate}
                  setIsStatisticsUpdate={setIsStatisticsUpdate}
                  subscribeToEvent={subscribeToEvent}
                />
              }
            />
            <Route
              exact
              path="/votes-page"
              element={
                <VotesPage
                  handleCurrentEvents={handleCurrentEvents}
                  toggleEventRegistration={toggleEventRegistration}
                  showEventResult={showEventResult}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                />
              }
            />

            <Route exact path="/join/:id" element={<Loader isLarge />} />

            <Route
              path={"*"}
              element={<NoMatchRoutes currentUser={currentUser} />}
            />
          </Route>
        </Routes>
        <VotesPageSuccessRegLaterModal
          isActive={isSuccessModalActive}
          handleShowSuccessModal={handleShowSuccessModal}
          successModalText={successModalText}
          newEventId={newEventId}
        />
      </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
