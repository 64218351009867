import { config } from "../config";

const API_URL = config.java_api_url;
const API_URL_AUTH = config.auth_api_url;

export const changeUserName = (accessToken, body) => {
  return fetch(`${API_URL}/users/${body.userNameId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body.userNameFields),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const changeUserPassword = (accessToken, body) => {
  return fetch(`${API_URL}/users/${body.userNameId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      password: body.password,
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      } else {
        throw new Error(err?.message || "Неизвестная ошибка");
      }
    });
};

export const updateUser = async (
  accessToken,
  {
    id,
    email = "",
    phone = "",
    password = "",
    confirmationCode = "",
    firstName = "",
    secondName = "",
    lastName = "",
  }
) => {
  return await fetch(`${API_URL_AUTH}/users/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      id,
      email,
      phone,
      password,
      confirmationCode,
      firstName,
      secondName,
      lastName,
    }),
  })
    .then((res) => (res.ok ? res.json() : res))

    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};
