export const config = {
    java_api_url: "https://v2.cryptoveche.ru/api",
    auth_api_url: "https://v2.cryptoveche.ru/auth",
    admin_url: "https://admin.rcsi.cryptoveche.ru/",
    ws_connect: "wss://rcsi.cryptoveche.ru/ws",
    ws_pass: "client",
    ws_user: "client",
    enable_esia: false,
    confidentiality: "http://docs.cryptoveche.ru/files/cryptoveche_pk.pdf",
    type: 'main',
    realm: "REALM_CRYPTOVECHE",
    header_view: "", // "edro"
    localStorageVersion: 2
};
